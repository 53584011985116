@import url(https://fonts.googleapis.com/css2?family=Fredoka+One&family=Varela+Round&display=swap);
* {
    margin: 0;
    padding: 0;
    font-family: "Fredoka One", cursive;
    color: white;
}

html,
body,
#root {
    width: 100%;
    height: 100%;
}

/* Fredoka One & Varela Round */

